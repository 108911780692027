<template>
    <modal id="comm-pref-modal" class="modal--padded" event="openCommunicationPreferenceModal" unclosable>
		<template slot="header">{{ modalTitle }}</template>
		<template slot="content">
			<div v-if="communicationData">
				<div v-if="!communicationDataSaved">
					<p>Via uw a.s.r. account heeft u altijd toegang tot uw gegevens. Laat ons weten of u berichten over uw verzekering voortaan digitaal wilt ontvangen in plaats van per post. Digitale post is snel, veilig en duurzaam:</p>
					<ul>
						<li>per e-mail sturen wij een melding dat er digitale post is</li>
						<li>het bericht staat voor u klaar op uw a.s.r. account</li>
					</ul>

					<div>
						<p>Akkoord met digitaal communiceren?</p>
						<input type="radio" id="yes" name="communicationPreference" :value="true" v-model="onlineCommunication">
						<label for="yes">ja, ik kies voor digitaal</label>
						<input type="radio" id="no" name="communicationPreference" :value="false" v-model="onlineCommunication">
						<label for="no">nee, liever niet</label>
					</div>

					<br><br>
					<protected-button :protect="axonImpersonation" title="Opslaan">
						<template #button>
							<action-button class="button--primary" @click="saveCommunicationPreference">Bevestig mijn voorkeur</action-button>
						</template>
					</protected-button>

					<p><i>U ontvangt een e-mail als bevestiging.</i></p>
				</div>

				<div v-else>
					<div class="saved-message">{{ savedChangesMessage }}</div>
				</div>
			</div>
		</template>
	</modal>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { StoreMutation } from "lib/types/vuex"

	import { namespace, State } from "vuex-class"

	import { CustomerState } from "store/customer/types"
	import { customer as customerApi } from "api/gateway"

	import LabeledInput from "components/form/LabeledInput.vue"
	import ActionButton from "components/button/ActionButton.vue"
	import ProtectedButton from "leven-shared-components/ProtectedButton.vue"
	import eventBus from "lib/vue/eventBus"
	import Modal from "components/modal/Modal.vue"

	const routing = namespace("routing")
	const customer = namespace("customer")

	@Component({
		components: {
			Modal,
			LabeledInput,
			ActionButton,
			ProtectedButton
		}
	})
	export default class CommunicationPreferenceModal extends Vue {

		@State customer!: CustomerState
		@State axonImpersonation!: boolean

		@customer.Mutation setOnlineCommunication!: StoreMutation<boolean | null>
		@routing.State changeCommunicationPreferenceClicked!: boolean
		@routing.Mutation initChangeCommunicationPreference!: StoreMutation<boolean>
		@routing.Mutation setCommunicationPreferenceAcquired!: StoreMutation<boolean>

		modalTitle: any | null = null

		communicationDataSaved = false
		basicData?: any = null
		communicationData?: any = null
		onlineCommunication: boolean = true
		emailCompare: string | null = null

		savedChangesMessage: string = "Uw wijzigingen zijn verzonden naar a.s.r."

		selectedItem: string = "Communicatievoorkeur"

		get changedEmail() {
			return this.emailCompare !== this.basicData.emailAddress1
		}

		async mounted() {
			await this.getCommunicationDataModal()
			eventBus.emit('openCommunicationPreferenceModal')
		}

		async getCommunicationDataModal() {
			await Vue.nextTick()

			if (this.customer.externalIdentifier) {
				this.basicData = await customerApi.getCustomerBasicdata(this.customer.externalIdentifier)
				this.communicationData = await customerApi.getCommunicationPreferences(this.customer.externalIdentifier)

				this.modalTitle = this.customer.previousLogin === undefined ? "Welkom in uw a.s.r. account" : "Welkom terug in uw a.s.r. account"
				this.emailCompare = this.basicData.emailAddress1
				if (!this.basicData.emailAddress1) {
					this.basicData.emailAddress1 = this.customer.userIdentifierExternalSystem || ""
				}
			}

			this.onlineCommunication = this.changeCommunicationPreferenceClicked || (this.customer.onlineCommunication || true)
		}

		async saveCommunicationPreference() {
			if (this.customer.externalIdentifier) {

				let communicationChannel = "Email"
				let receivesMailing = true

				if (!this.onlineCommunication) {
					communicationChannel = "Mail"
					receivesMailing = false
				}

				this.communicationData.customerCommunicationTypeForPolicyDocumentsRef.externalIdentifier = communicationChannel
				this.communicationData.communicationTypeForClaimDocumentsRef.externalIdentifier = communicationChannel
				this.communicationData.receivesMailing = receivesMailing

				await customerApi.postCommunicationPreferences(this.customer.externalIdentifier, this.communicationData)

				if (this.changedEmail) {
					const basicDataNew = await customerApi.getCustomerBasicdata(this.customer.externalIdentifier)
					basicDataNew.emailAddress1 = this.basicData.emailAddress1

					await customerApi.postCustomerBasicData(this.customer.externalIdentifier, basicDataNew)
				}

				this.initChangeCommunicationPreference(false)
				this.modalTitle = "Bedankt!"
				this.communicationDataSaved = true

				setTimeout(() => {
					this.communicationDataSaved = false
					this.setCommunicationPreferenceAcquired(true)
					this.setOnlineCommunication(this.onlineCommunication)
				}, 2500)
			}
		}
	}
</script>

<style>
	@import "custom/communication-preferences-modal.css";
</style>
