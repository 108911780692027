<template>
	<h2>
		<span v-if="hours >= 6 && hours < 12"><slot name="goodmorning"></slot></span>
		<span v-else-if="hours >= 12 && hours < 18"><slot name="goodafternoon"></slot></span>
		<span v-else-if="hours >= 18 && hours < 23"><slot name="goodevening"></slot></span>
		<span v-else><slot name="goodnight"></slot></span>
		<slot></slot>
	</h2>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class Welcome extends Vue {
		hours = new Date().getHours()
	}
</script>