<template>
	<div class="message" @click.prevent="clicked">
		<div class="message__date" v-if="hasDate">
			<slot name="date"></slot>
		</div>
		<div class="message__content">
			<div class="message__icon" v-if="hasIcon">
				<slot name="icon"></slot>
			</div>
			<slot name="content"></slot>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class Message extends Vue {

		hasDate = false
		hasIcon = false

		mounted() {
			this.hasDate = !!this.$slots.date
			this.hasIcon = !!this.$slots.icon
		}

		clicked(e: MouseEvent) {
			this.$emit("click", e)
		}
	}
</script>

<style>
	@import "widget/message.css";
</style>