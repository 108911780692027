<template>
	<div class="notice">
		<slot></slot>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class Notice extends Vue {}
</script>

<style>
	@import "supplemental/notice.css";
</style>